@charset "utf-8";

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

img + em {
    display: block;
    text-align: center;
}

.splash .page__inner-wrap {
    float: left;
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear:both
}


// Life in weeks
.life-in-weeks {
	h2 {
		margin-top: 0;
	}
	.border-tertiary {
		border-color: #eee;
		color: #666;
	}
	.border-tertiary a {
		color: #666;
	}
	.week {
		a {
			text-decoration: none;
		}
	}
	.future-date {
		background-color: #eee;
	}
}

.float-left {
    float: left !important;
}

.border {
    border: 1px solid #dee2e6!important;
}

.border-secondary {
    border-color: #6c757d!important;
}

.border-tertiary {
    border-color: #eee;
    color: #666;
}

// Pop-overs
h3.popover-header {
	margin: 0;
	margin-block-start: 0;
}
.popover .arrow {
  display: none;
}
.popover {
  border: 5px solid #334;
}

.emoji {
	width: 1em;
	height: 1em;
	vertical-align: -0.125em; /* Align with text */
}

.svg-bg {
    background-color: white; /* White background */
    padding: 10px; /* Optional: Adds spacing */
    display: inline-block; /* Ensures background applies correctly */
}

// Stuff brought in from Font Awesome

// .fa,.fa-brands,.fa-regular,.fa-solid,.fab,.far,.fas {
//     -moz-osx-font-smoothing: grayscale;
//     -webkit-font-smoothing: antialiased;
//     display: var(--fa-display,inline-block);
//     font-style: normal;
//     font-variant: normal;
//     line-height: 1;
//     text-rendering: auto
// }

// .fa-brands:before,.fa-regular:before,.fa-solid:before,.fa:before,.fab:before,.far:before,.fas:before {
//     content: var(--fa)
// }

// .fa-classic,.fa-regular,.fa-solid,.far,.fas {
//     font-family: "Font Awesome 6 Free"
// }

// .fa-brands,.fab {
//     font-family: "Font Awesome 6 Brands"
// }

// .fa-fw {
//     text-align: center;
//     width: 1.25em
// }

// .fa-calendar-alt,.fa-calendar-days {
//     --fa: "\f073"
// }

// :host,:root {
//     --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free"
// }

// .fa-regular,.far {
//     font-weight: 400
// }

// :host,:root {
//     --fa-style-family-classic: "Font Awesome 6 Free";
//     --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free"
// }

// .fa-solid,.fas {
//     font-weight: 900
// }